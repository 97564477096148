import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Page, Metadata } from '~/components/Layout'
import More from '~/components/Blog/More'

const MoreArticlesPage = ({ data }) => (
  <Page>
    <Metadata
      title="Blog | More Articles"
      description="All of our Caraway articles in one place. Learn about our design, community, special reports, partnerships and everything in between." />
    <More data={data} />
  </Page>
)

export default MoreArticlesPage

export const query = graphql`
  {
    articles: allContentfulBlogArticle {
      nodes {
        tag {
          slug
          name
        }
        category {
          slug
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        slug
        publishDate
        cookedBy
        image {
          gatsbyImageData(
            quality: 80
            width: 400
          )
          file {
            url
          }
          title
        }
        content {
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    tags: allContentfulBlogTag {
      nodes {
        slug
        name
      }
    }
  }
`

MoreArticlesPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    tags: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
}
